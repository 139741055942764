import View from "./view.js";
import Chart from "chart.js/auto";

/**
 * Contains all methods and properties relevent to the pieView UI.
 */
class PieView extends View {
  _chartElement = document.getElementById("myChart");
  myChart;
  pieChartTheme = [
    "rgb(255, 0, 174)",
    "rgba(34, 169, 227, 0.25)",
    "rgb(233, 242, 12)",
  ];
  pieChartFontColor = "#faf0f0";
  constructor() {
    super();
    this._displayChart();
  }
  _defaultChartData = {
    wins: 14,
    draws: 4,
    loses: 6,
  }

  _displayChart() {
    const data = {
      labels: ["ชนะ", "เสมอ", "แพ้"],
      datasets: [
        {
          label: "100เรื่องFootball",
          data: [this._defaultChartData.wins, this._defaultChartData.draws, this._defaultChartData.loses],
          backgroundColor: this.pieChartTheme,
          hoverOffset: 4,
        },
      ],
    };

    const config = {
      type: "doughnut",
      data: data,
      options: {
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: "ชนะ/เสมอ/แพ้",
            font: {
              size: "20px",
            },
            color: this.pieChartFontColor,
          },
          legend: {
            labels: {
              color: this.pieChartFontColor,
            },
          },
        },
      },
    };
    this.myChart = new Chart(this._chartElement, config);
  }
}

export default new PieView();
