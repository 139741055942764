import View from "./view";

/**
 * Contains all methods and properties relevent to the comparisonView UI.
 */
class Comparison extends View {
  _parentElement = document.querySelector(".prediction__section--content");
  _outcomeElement = document.querySelector(".prediction__section--winner");
  constructor() {
    super();
  }

  renderOutcome(){
      this._outcomeElement.innerHTML = ""
      const markup = `<div>
              <p class="result">Winner</p>
              <p class="team">${this._data.outcomePrediction.name}</p>
              <p class="outcomes">${
                this._data.outcomePrediction.comment === null
                  ? ""
                  : this._data.outcomePrediction.comment
              }</p>
            </div>`;
            this._outcomeElement.insertAdjacentHTML("afterbegin", markup)
  }

  _generateMarkup() {
    return `
        <table>
            <tbody>
                <tr>
                    <th></th>
                    <th>เปรียบเทียบ</th>
                    <th></th>
                </tr>
                <tr>
                    <th>${this._data.homeName}</th>
                    <th>พบกับ</th>
                    <th>${this._data.awayName}</th>
                </tr>

                <tr>
                    <td>${this._data.homeWins}</td>
                    <td>ชนะ</td>
                    <td>${this._data.awayWins}</td>
                </tr>
                <tr>
                    <td>${this._data.homeDraws}</td>
                    <td>เสมอ</td>
                    <td>${this._data.awayDraws}</td>
                </tr>
                <tr>
                    <td>${this._data.homeLoses}</td>
                    <td>แพ้</td>
                    <td>${this._data.awayLoses}</td>
                </tr>
                <tr>
                    <td>${this._data.overUnderGoalsData.home}</td>
                    <td>ประตู ได้/เสีย</td>
                    <td>${this._data.overUnderGoalsData.away}</td>
                </tr>
                <tr>
                    <td></td>
                    <th>โอกาสชนะ</th>
                    <td></td>
                </tr>
                <tr>
                    <td>เจ้าบ้าน: ${this._data.outcomePercentage.home}</td>
                    <td>เสมอ: ${this._data.outcomePercentage.draw}</td>
                    <td>ทีมเยือน: ${this._data.outcomePercentage.away}</td>
                </tr>
            </tbody>
        </table>`;
  }
};

export default new Comparison()