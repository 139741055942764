import View from "./view.js";

/**
 * Contains all methods and properties relevent to the team stats UI
 */
class Team extends View {
  _parentElement = document.querySelector(".team__stats--content");

  _formatMostGoals(goals){
    return goals.home > goals.away? goals.home : goals.away
  };

  _generateMarkup(){
    return `
        <div class="team__stats--box">
            <div class="team__stat--discription">
                <h6>ประตูเฉลี่ยที่ทำได้</h6>
            </div>
            <div class="team__stat--num">
                <p>${this._data.avgGoalsScored}</p>
            </div>
        </div>

        <div class="team__stats--box">
            <div class="team__stat--discription">
                <h6>ประตูเฉลี่ยต่อนัด</h6>
            </div>
            <div class="team__stat--num">
                <p>${this._data.avgGoalsAgainst}</p>
            </div>
        </div>

        <div class="team__stats--box">
            <div class="team__stat--discription">
                <h6>ทำประตูรวม</h6>
            </div>
            <div class="team__stat--num">
                <p>${this._data.totalGoalScored}</p>
            </div>
        </div>

        <div class="team__stats--box">
            <div class="team__stat--discription">
                <h6>ประตูรวม</h6>
            </div>
            <div class="team__stat--num">
                <p>${this._data.totalGoalsAgainst}</p>
            </div>
        </div>

        <div class="team__stats--box">
            <div class="team__stat--discription">
                <h6>ชนะต่อเนื่องยาวที่สุด</h6>
            </div>
            <div class="team__stat--num">
                <p>${this._data.longestWinStreak}</p>
            </div>
        </div>

        <div class="team__stats--box">
            <div class="team__stat--discription">
                <h6>ทำประตูมากที่สุด ในนัดเดียว</h6>
            </div>
            <div class="team__stat--num">
                <p>${this._formatMostGoals(this._data.mostGoalsOneGame)}</p>
            </div>
        </div>
        `;
    };
}

export default new Team();